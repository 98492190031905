import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import * as _ from 'lodash-es';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: AuthService)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.

        const thirdParty = _.filter(thirdPartyAuthUrl, el => newReq.url.indexOf(el) !== -1);
        if (thirdParty.length === 0) {
            if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
            {
                newReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
                });
            }
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {
                    const checkAuthUrl = _.filter(authUrl, el => error.url.indexOf(el) !== -1);
                    if (checkAuthUrl.length === 0) {
                        // Sign out
                        this._authService.signOut();

                        // Reload the app
                        location.reload();
                    }
                }

                return throwError(error);
            })
        );
    }
}

const authUrl = [
    'auth/login',
    'auth/refresh-token',
    'auth/confirm',
    'auth/sign-up',
    'auth/logout',
    'auth/forgot-password',
    'auth/change-password',
    'auth/password-token-reset',
    'v1/shortLinks',
    'buyer/payout',
];

const thirdPartyAuthUrl = [
    'v1/shortLinks',
    'buyer/payout',
];
