/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'apps.admin',
        title   : 'Admin',
        type    : 'group',
        children: [
            {
                id   : 'dashboards.admin-payout',
                title: 'Merchant list',
                type : 'basic',
                icon : 'fruits:merchant',
                link : '/admin/payout-list',
            },
            {
                id   : 'dashboards.admin-payout-history',
                title: 'Payout history',
                type : 'basic',
                icon : 'fruits:usd',
                link : '/admin/payout-history',
            },
        ],
        hidden: (): any => {
            const user = JSON.parse(localStorage.getItem('user'));

            if (user.roles) {
                return user.roles[0] !== ROLE.admin;
            } else if (user.role) {
                return user && user.role !== ROLE.admin;
            }
        },
    },
    {
        id      : 'apps.trading',
        title   : 'Trading',
        type    : 'group',
        children: [
            {
                id   : 'trading.orders',
                title: 'Orders',
                type : 'basic',
                icon : 'fruits:orders',
                link : '/trading/orders'
            },
            {
                id   : 'dashboards.withdraws',
                title: 'Withdrawals',
                type : 'basic',
                icon : 'fruits:withdrawals',
                link : '/trading/withdraws',
                hidden: () => true // FRUITSC-1511
            },
        ]
    },
    {
        id      : 'apps.accountSettings',
        title   : 'Account Settings',
        type    : 'group',
        children: [
            {
                id   : 'accountSettings.basic',
                title: 'Basic Settings',
                type : 'basic',
                icon : 'fruits:basic-settings',
                link : '/merchant/basic-settings'
            },
            {
                id   : 'dashboards.changePassword',
                title: 'Change Password',
                type : 'basic',
                icon : 'fruits:change-password',
                link : '/merchant/change-password'
            },
            {
                id   : 'dashboards.merchantSettings',
                title: 'Merchant Settings',
                type : 'basic',
                icon : 'fruits:merchant-settings',
                link : '/merchant/merchant-settings'
            },
            {
                id   : 'dashboards.apiKeys',
                title: 'API Keys',
                type : 'basic',
                icon : 'fruits:api-keys',
                link : '/merchant/api-keys',
                hidden: (): boolean => true
            },
            {
                id   : 'dashboards.apiKeys',
                title: 'Button Maker',
                type : 'basic',
                icon : 'heroicons_outline:code',
                link : '/merchant/button-maker'
            }
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [];

export const futuristicNavigation: FuseNavigationItem[] = [];

export const horizontalNavigation: FuseNavigationItem[] = [];

export enum ROLE {
    admin = 'ADMIN',
    merchant = 'MERCHANT',
}
