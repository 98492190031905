import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {AnalyticsService} from "./analytics.service";
import {ActivatedRoute, NavigationEnd, Router, RouterState} from "@angular/router";
import {filter} from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(@Inject(DOCUMENT) private document: any,
                private router: Router,
                private analyticsService: AnalyticsService) {
        this.handleRouteEvents();
    }

    ngOnInit(): void {
    }

    handleRouteEvents(): any {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            setTimeout(() => {
                this.analyticsService.logEvent('page_view', {
                    page_path: event.urlAfterRedirects,
                    page_location: this.document.location.href
                });
            });
        });
    }

}
