import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {UserService} from 'app/core/user/user.service';
import {environment} from '../../../environments/environment';
import {constants} from '../../util/constants';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ISignUp} from '../../modules/auth/sign-up/sign-up.component';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    private token = this.accessToken;
    private _baseUrl = environment.production ? constants.mainnetUrl : constants.testnetUrl;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _jwtHelper: JwtHelperService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    set user(user: any) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post(`${this._baseUrl}/webapi/auth/forgot-password`, {email: email});
    }

    /**
     * Reset password
     *
     * @param data
     */
    resetPassword(data: {password: string; passwordConfirm: string; token: string}): Observable<any>
    {
        return this._httpClient.put(`${this._baseUrl}/webapi/auth/change-password`, data);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        const data = {
            username: credentials.email,
            password: credentials.password
        };

        return this._httpClient.post(`${this._baseUrl}/webapi/auth/login`, data);
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post(`${this._baseUrl}/webapi/auth/refresh-token`, {
            refreshToken: this.refreshToken
        }, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        }).pipe(
            catchError(() =>
                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                if (!response || response.code !== 0) {
                    return of(false);
                }

                const { data } = response;

                // Store the access token in the local storage
                this.accessToken = data.accessToken;
                this.refreshToken = data.refreshToken;
                this.user = this._jwtHelper.decodeToken(data.accessToken);

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param data
     */
    signUp(data: ISignUp): Observable<any>
    {
        return this._httpClient.post(`${this._baseUrl}/webapi/auth/sign-up`, data);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if (this._jwtHelper.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    checkConfirmCode(data: {username: string; confirmCode: string}): Observable<any> {
        return this._httpClient.post(`${this._baseUrl}/webapi/auth/confirm`, data).pipe(
            catchError(() =>
                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                if (!response || response.code !== 0) {
                    return of(response);
                }
                // Store the access token in the local storage
                this.accessToken = response.data.accessToken;
                this.refreshToken = response.data.refreshToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user in the local storage
                this.user = this._jwtHelper.decodeToken(response.data.accessToken);

                // Return a new observable with the response
                return of(response.data);
            })
        );
    }

    checkToken(token: string): Observable<any> {
        return this._httpClient.get(`${this._baseUrl}/webapi/auth/password-token-reset/${token}`);
    }
}
