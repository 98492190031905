import {environment} from '../../../environments/environment';
import {constants} from '../../util/constants';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../core/user/user.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {IUpdateBasicSettings} from './basic-settings/basic-settings.component';
import {IMerchantSettings, MerchantSetting} from './merchant-settings/merchant-settings.component';
import {IRequestUpdatePassword, IUpdatePassword} from './change-password/change-password.component';
import {ICreateApiKey} from './api-keys/api-keys.component';
import {IGetApiKeyLogs} from './api-keys/api-key-log/api-key-log.component';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class MerchantService {

    private _baseUrl = environment.production ? constants.mainnetUrl : constants.testnetUrl;

    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _jwtHelper: JwtHelperService
    ) {
    }

    public getBasicSettings(): Observable<any> {
        const user = this.user;
        return this._httpClient.get(`${this._baseUrl}/webapi/merchant/${user.merchantId}/basic`, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public getAllTimezone(): Observable<any> {
        return this._httpClient.get(`${this._baseUrl}/webapi/master/timezone`, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public updateBasicSettings(data: IUpdateBasicSettings): Observable<any> {
        return this._httpClient.patch(`${this._baseUrl}/webapi/merchant/basic`, data, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public getMerchantSettings(): Observable<any> {
        const user = this.user;
        return this._httpClient.get(`${this._baseUrl}/webapi/merchant/${user.merchantId}/setting`, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public updateMerchantSettings(data: MerchantSetting): Observable<any> {
        return this._httpClient.post(`${this._baseUrl}/webapi/merchant/setting`, data, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public requestUpdatePassword(data: IRequestUpdatePassword): Observable<any> {
        data.userId = this.user.userId;
        return this._httpClient.post(`${this._baseUrl}/webapi/merchant/change-password`, data, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public updatePassword(otp: string): Observable<any> {
        const data: IUpdatePassword = {
            userId: this.user.userId,
            otp: otp
        };
        return this._httpClient.post(`${this._baseUrl}/webapi/merchant/change-password-otp`, data, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public getPagingApiKey(): Observable<any> {
        const data = {
            page: 0,
            size: 1000
        };
        return this._httpClient.post(`${this._baseUrl}/webapi/merchant/api-keys`, data, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public generateNewApiKey(data: ICreateApiKey): Observable<any> {
        return this._httpClient.post(`${this._baseUrl}/webapi/merchant/api-key/save`, data, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public getApiKey(keyId: string): Observable<any> {
        return this._httpClient.get(`${this._baseUrl}/webapi/merchant/api-key/${keyId}`, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Access-Token': this.accessToken
            }
        });
    }

    public updateApiKeyPermission(data: any): Observable<any> {
        return this._httpClient.post(`${this._baseUrl}/webapi/merchant/api-key/save`, data, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { 'Access-Token': this.accessToken }
        });
    }

    public getApiKeyLogs(data: IGetApiKeyLogs): Observable<any> {
        return this._httpClient.post(`${this._baseUrl}/webapi/merchant/api-key/log`, data, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { 'Access-Token': this.accessToken }
        });
    }



    /**
     * Setter & getter for access token
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get user(): any {
        const user = localStorage.getItem('user');
        return user ? JSON.parse(user) : undefined;
    }

}
