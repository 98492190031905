<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-[#FFF] print:hidden text-[#3A4667]"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0 mb-10">
            <svg width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M35.1729 17.7006C35.1729 27.4881 27.3107 35.4013 17.5865 35.4013C7.86218 35.4013 0 27.4881 0 17.7006C0 7.91323 7.86218 0 17.5865 0C27.3107 0 35.1729 7.91323 35.1729 17.7006Z" fill="#ED6D00"/>
                <path d="M4.60406 22.3862L3.93164 24.9372L15.9835 28.3212L19.2939 15.306L28.0871 17.7528L28.7596 15.2019L19.9146 12.755L20.4836 10.4643L30.1561 13.2236L30.7768 10.6726L18.7249 7.28864L15.4146 20.3559L6.62133 17.857L6.00063 20.3559L14.7939 22.8548L14.2249 25.1455L4.60406 22.3862Z" fill="white"/>
                </svg>
            <h1 class="font-bold text-primary text-4xl ml-2">FRUITS</h1>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b print:hidden bg-[#FAFAFC]">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">FRUITS &copy; {{currentYear}}</span>
    </div>

</div>

<!-- Quick chat -->
