export const environment = {
    production: false,
    isMainNet: false,
    firebase: {
        apiKey: "AIzaSyBcTfOwWSs4cucR0wxNdPOzf-kl1NhIGP4",
        authDomain: "fruits-test-5aba6.firebaseapp.com",
        projectId: "fruits-test-5aba6",
        storageBucket: "fruits-test-5aba6.appspot.com",
        messagingSenderId: "682693683669",
        appId: "1:682693683669:web:b2d7fe8fb057406683f55c",
        measurementId: "G-VCD6108YCY"
    }
};
