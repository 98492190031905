
export const constants = {
    mainnetUrl: 'https://web-api.fruitspay.com',
    testnetUrl: 'https://api-payment.fruits-station.com',
    signUpCode: 'fruits@2022',
    coinGeckoUrl: 'https://api.coingecko.com/api/v3/coins/fruits',
    testnetWallet: 'https://testnet.fwallet.net',
    mainnetWallet: 'https://fwallet.net',
    multiCoin: 'https://multi-coin.fwallet.net',
    multicoinTestURL: 'https://testnet-multicoin.fwallet.net',
    marketplaceTestnet: 'https://testnet2-marketplace.fwallet.net',
    marketplaceMainnet: 'https://marketplace.fwallet.net',

    mainnetFruitspay:  'https://fruitspay.com/#',
    testnetFruitspay:  'https://payment.fruits-station.com/#',

    testnetStripeKey: 'pk_test_51LfxcNAXYdqbPoAZHAVEAFd76jcP50n1S4WZ4JbfhKf2LGVMo5DyxDCLNoHImbfl0ta0tnSrYW9cKodE7UWFvkwg00Jqh1I9Fp',
    mainnetStripeKey: 'pk_live_51LXH5dCzzXZIykROldB7SEKeE4BTCc67d3qVC3PuoqffZYUFkpt0vRdWvEL9kND0ngSuoMRst0BghQxaYrVWWIGk00rE2IpuPY',

    // Fruits Donation Link
    mainnetFruitsDonation: 'https://fruits-station.com/region/',
    testnetFruitsDonation: 'https://fruits-station.com/region/',

    // Fruits NFT Link
    mainnetFruitsNFT: 'https://fruitsnft.io/nft/my-nfts?status=success',
    testnetFruitsNFT: 'https://testnet.fruitsnft.io/nft/my-nfts?status=success',
    mainnetFruitsNFTHome: 'https://fruitsnft.io',
    testnetFruitsNFTHome: 'https://testnet.fruitsnft.io',

    // Fruits NFT origin
    testnetFruitsOrigin: 'https://testnet.fruitsnft.io',
    mainnetFruitsOrigin: 'https://fruitsnft.io',
    jp: 'jp',
    // deepLink fruits wallet app
    openWalletAppUrl: 'fruitscoin://wallet',
    pageTitlePrefix: 'FRUITS PAYMENT | ',
    billingInformationText: '※Billing information will be sent to this email address, so please check this information carefully.'
};

export enum CoinMaxDecimal {
    frts = 4,
    btc = 8,
    eth = 6,
    usdt = 6, // TODO: QA
    smile = 8
}

export enum COINTYPE {
    frts = 'FRTS',
    btc = 'BTC',
    eth = 'ETH',
    paypal = 'PAYPAL',
    usd = 'USD',
    gt = 'GT',
    usdt = 'USDT',
    trxusdt = 'TRXUSDT',
    smile = 'SMILE'
}

export const PAYMENT_METHODS = {
    btc: { coinType: COINTYPE.btc, name: 'Bitcoin' },
    eth: { coinType: COINTYPE.eth, name: 'Ethereum' },
    frts: { coinType: COINTYPE.frts, name: 'Fruits' },
    usd: { coinType: COINTYPE.usd, name: 'USD ( Credit Card )' },
    gt: { coinType: COINTYPE.gt, name: 'GT'},
    usdt: {coinType: COINTYPE.usdt, name: 'Tether USD ( ERC20 )'},
    trxusdt: {coinType: COINTYPE.trxusdt, name: 'Tether USD ( TRC20 )'},
    smile: {coinType: COINTYPE.smile, name: 'Smile'}
};

export enum CoinDecimal {
    frts = 8
}
