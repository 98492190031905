import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {MarkdownModule} from 'ngx-markdown';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {mockApiServices} from 'app/mock-api';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {JwtModule} from '@auth0/angular-jwt';
import {NotifierModule} from 'angular-notifier';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {MerchantModule} from './modules/merchant/merchant.module';
import {HIGHLIGHT_OPTIONS, HighlightModule, HighlightOptions} from 'ngx-highlightjs';
import * as firebase from 'firebase/app';
import {environment} from '../environments/environment';

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

firebase.initializeApp(environment.firebase);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // Function module of your application
        MerchantModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('accessToken'),
            }
        }),
        // Angular notifier
        NotifierModule,
        HighlightModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                lineNumbers: true,
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
                languages: {
                    typescript: () => import('highlight.js/lib/languages/typescript'),
                    css: () => import('highlight.js/lib/languages/css'),
                    xml: () => import('highlight.js/lib/languages/xml'),
                    javascript: () => import('highlight.js/lib/languages/javascript'),
                },
            } as HighlightOptions,
        },
    ]
})
export class AppModule
{
}
