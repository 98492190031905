import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '../../core/auth/auth.interceptor';
import {MerchantService} from './merchant.service';


@NgModule({
    declarations: [

    ],
    imports: [
        CommonModule,
    ],
    providers: [
        MerchantService,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true
        }
    ]
})
export class MerchantModule {

}
