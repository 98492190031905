<!-- Button -->
<button
    class="fuse-mat-button-large w-full bg-[#F3F5F9]"
    mat-flat-button
    [matMenuTriggerFor]="languages">
    <ng-container>
        {{getLang(activeLang)}}
    </ng-container>
</button>

<!-- Language menu -->
<mat-menu
    [xPosition]="'before'"
    #languages="matMenu">
    <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
        <button
            mat-menu-item
            (click)="setActiveLang(lang.id)">
            <span class="flex items-center">
                <span class="ml-3">{{lang.label}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>
