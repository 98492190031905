import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable()
export class FuseConfirmationService
{
    private _defaultConfig: FuseConfirmationConfig = {
        title      : 'Confirm action',
        message    : 'Are you sure you want to confirm this action?',
        icon       : {
            show : true,
            name : 'heroicons_outline:exclamation',
            color: 'warn'
        },
        actions    : {
            confirm: {
                show : true,
                label: 'Confirm',
                color: 'warn'
            },
            cancel : {
                show : true,
                label: 'Cancel'
            },
            openApp: {
                show : false,
                label: 'Open App',
                linkApp: '',
            },
            openUrl: {
                show : false,
                label: 'Open Store',
                url  : 'https://fruistnft.io'
            }
        },
        dismissible: false
    };

    /**
     * Constructor
     */
    constructor(
        private _matDialog: MatDialog,
        private _deviceDetectorService: DeviceDetectorService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent>
    {
        // Set app store Url
        const deviceInfo = this._deviceDetectorService.getDeviceInfo();
        let storeUrl = '';
        if (deviceInfo.os === 'iOS') {
            storeUrl = 'https://apps.apple.com/us/app/fruits-wallet/id1585148044';
        } else {
            storeUrl = 'https://play.google.com/store/apps/details?id=com.fruits.wallet';
        }
        this._defaultConfig.actions.openUrl.url = storeUrl;

        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus   : false,
            disableClose: !userConfig.dismissible,
            data        : userConfig,
            panelClass  : 'fuse-confirmation-dialog-panel',
            width: '400px'
        });
    }
}
