<button
    class="fuse-mat-button-large w-full bg-[#F3F5F9]"
    mat-flat-button
    type="button"
    (click)="signOut()"
    [color]="'primary'"
>
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="#B9C1D9"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.66699 11H4.00033V12.3333H12.0003V1.66666H4.00033V2.99999H2.66699V0.999995C2.66699 0.823184 2.73723 0.653615 2.86225 0.52859C2.98728 0.403566 3.15685 0.333328 3.33366 0.333328H12.667C12.8438 0.333328 13.0134 0.403566 13.1384 0.52859C13.2634 0.653615 13.3337 0.823184 13.3337 0.999995V13C13.3337 13.1768 13.2634 13.3464 13.1384 13.4714C13.0134 13.5964 12.8438 13.6667 12.667 13.6667H3.33366C3.15685 13.6667 2.98728 13.5964 2.86225 13.4714C2.73723 13.3464 2.66699 13.1768 2.66699 13V11ZM4.00033 6.33333H8.66699V7.66666H4.00033V9.66666L0.666992 7L4.00033 4.33333V6.33333Z"
            fill="#B9C1D9"
        />
    </svg>

    <span class="text-[#3A4667] ml-4"> Logout </span>
</button>
